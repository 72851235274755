<template>
  <div style="height: 100vh">
    <div class="columns is-centered is-vcentered">
      <div class="column is-half" style="height: 100%">
        <div class="card">
          <div class="card-header has-background-dark">
            <div class="card-header-title has-text-white is-centered">
              <!--<span>{{ siteName }} Papara Transfer</span>-->
              <img width="300" :src="`/logos/${site}/crypto.svg`" />
            </div>
          </div>
          <div class="card-content is-centered" v-if="tab === 0">
            <p class="mb-4 p-3 has-text-centered has-background-danger has-text-white">{{ errMsg }}</p>
            <div class="is-flex is-justify-content-end">
              <b-button type="is-danger" @click="windowClose" size="is-small">Kapat</b-button>
            </div>
          </div>
          <section class="section is-main-section" v-if="tab === 1">
            <card-component title="Yatırım Bilgileriniz">
              <form @submit.prevent="save">
                <b-field label="From Currency" message="Please choose from currency">
                  <b-select size="is-small" v-model="form.fromCurrency">
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </b-select>
                </b-field>
                <b-field label="To Coin Currency" message="Please choose to coin currency">
                  <b-select size="is-small" v-model="form.toCurrency">
                    <option value="USDT.TRC20">USDT (TRC20)</option>
                    <!--<option value="LTC">Litecoin (LTC)</option>-->
                    <!--<option value="ETH">Ethereum (ETH)</option>-->
                  </b-select>
                </b-field>
                <b-field label="Yatırım tutarı" message="Minimum 10">
                  <b-input v-model="form.amount" size="is-small"/>
                </b-field>
                <div class="is-flex is-full is-justify-content-end">
                  <b-button class="mr-3" type="is-danger" size="is-small" @click="windowClose">Kapat</b-button>
                  <b-button :disabled="!isValid" type="is-success" size="is-small" native-type="submit">Devam et</b-button>
                </div>
              </form>
            </card-component>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import {
  IntegrationService,
  TransactionService,
  UserService,
} from "@/services";

export default {
  name: "CoinPayIframe",
  components: {CardComponent},
  data() {
    return {
      loading: false,
      error: false,
      errMsg: "",
      tab: 0,
      form: {
        appKey: this.$route.query.appKey,
        appSecret: this.$route.query.appSecret,
        processId: this.$route.query.processId,
        username: this.$route.query.username,
        name: this.$route.query.name,
        amount: this.$route.query.amount,
        fromCurrency: this.$route.query.fromCurrency || 'USD',
        toCurrency: this.$route.query.toCurrency || 'USDT.TRC20',
        userId: this.$route.query.userId,
        jwt: this.$route.query.jwt
      },
    };
  },
  computed: {
    isValid() {
      return (
          this.form.appKey &&
          this.form.appSecret &&
          this.form.userId &&
          this.form.username &&
          this.form.name &&
          this.form.amount >= 1
      );
    },
  },
  mounted() {
    const html = document.getElementsByTagName("html")[0];
    html.classList = [];
    this.init();
  },
  methods: {
    init() {
      UserService.providerCheck(this.form)
          .then(() => (this.tab = 1))
          .catch(() => (this.error = true));
    },
    async save() {
      const request = {
        processId: this.form.processId,
        username: this.form.username,
        name: this.form.name,
        amount: this.form.amount,
        userId: this.form.userId,
        fromCurrency: this.form.fromCurrency,
        toCurrency: this.form.toCurrency,
        jwt: this.form.jwt,
        successRedirectUrl: 'https://'+process.env["VUE_APP_NAME "] +'/coin/success',
        errorRedirectUrl: 'https://'+process.env["VUE_APP_NAME "] +'/coin/error'
      };
      const {data} = await TransactionService.hashCreate(request, this.form.appSecret);
      IntegrationService.coinDeposit(request, data, this.form.appKey)
          .then((res) => {
            window.location.href = res.data.depositUrl
          })
          .catch((err) => {
            this.tab = 0;
            this.errMsg = err.message;
          });
    },
  },
};
</script>
